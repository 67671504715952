<template>
  <div class="rb-page about-page">
    <div class="container">
      <div class="white-box">
        <div class="row">
          <div class="col-lg-7">
            <h1 class="rb-page--header">About us</h1>
            <p class="about-page--header__sub">
              {{ siteName }} is a social network for fast and convenient acquaintance with new people
              around the globe.
            </p>
            <p class="about-page--text">
              The task of our service is to realize new acquaintances via the capabilities of modern
              digital technologies. We know, that every person has the other half somewhere on this
              planet and for this we created a modern service, that will shorten the huge distances
              and remove various obstacles, that prevent people from finding each other.
            </p>
          </div>
          <div class="col-lg-4 offset-lg-1">
            <div class="about-page--sidebar">
              <div class="about-page--sidebar__inner">
                <ul class="list-unstyled">
                  <li class="set-flex">
                    <span class="icon rbi-message" />
                    <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
                  </li>
                  <li class="set-flex">
                    <span class="icon rbi-phone" />
                    <a :href="`tel:${sitePhoneWithotSpace}`">{{ sitePhone }}</a>
                  </li>
                  <li class="set-flex">
                    <span class="icon rbi-location" />
                    <span>{{ settings.company_name }}, {{ settings.company_address }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="about-page--footer text-center">
          <p class="about-page--footer__main">
            We Always Keep Up With The Desires Of Social Network Users
          </p>
          <p class="about-page--footer__sub">
            in search of new acquaintances and with the vector of modern Internet technologies
          </p>
        </div>
      </div>
    </div>
    <toasts />
    <reg-modal />
    <confirm-restore-profile v-if="restoreModal.open" />
  </div>
</template>

<script>
import Toasts from '../../Authorized/Toast.vue';
import RegModal from '../modals/RegisterModal.vue';
import ConfirmRestoreProfile from '../modals/ConfirmRestoreProfile.vue';
import { LOAD_PROFILE } from '../../../mixins/types/static';

export default {
  components: {
    Toasts,
    RegModal,
    ConfirmRestoreProfile,
  },
  mixins: [LOAD_PROFILE],
  data() {
    return {};
  },
  computed: {
    restoreModal() {
      return this.$store.getters.restoreProfileModal;
    },
    settings() {
      return this.$store.getters.siteSettings;
    },
    siteName() {
      return this.$store.getters.siteSettings.site_name
    },
    supportEmail() {
      return this.$store.getters.siteSettings.support_email
    },
    sitePhone() {
      return this.$store.getters.siteSettings.site_phone
    },
    sitePhoneWithotSpace() {
      return this.$store.getters.siteSettings.site_phone.replace(/\s/g, '');
    },
  },
  mounted() {
    document.title = 'About us';
  },
};
</script>

<style scoped lang="scss">
@import '../../../assets/scss/vars';
p,
.rb-page .ul-styled li {
  margin-bottom: 0.5em;
}

.about-page {
  &--header {
    color: $black;
    font-size: 26px;
    font-weight: 400;
    line-height: 26px;
    &::first-letter {
      color: $red;
    }
    &__sub {
      color: $black;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.57;
      letter-spacing: 1.4px;
    }
  }
  &--text {
    color: $black;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1.4px;
  }
  &--sidebar {
    background: $light-grey;
    padding: 44px 35px;
    ul {
      margin: 0;
      padding: 0;
      li {
        margin-bottom: 35px;
        color: $black;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: 1.4px;
        &:last-child {
          margin-bottom: 0;
        }
        .icon {
          margin-right: 15px;
          font-size: 21px;
        }
        a {
          color: $red;
        }
      }
    }
  }
  &--footer {
    margin-top: 40px;
    padding: 50px 0;
    text-align: center;
    border-top: 4px solid $red;
    border-bottom: 4px solid $red;
    &__main {
      color: $black;
      font-size: 40px;
      line-height: 1.4;
      letter-spacing: 2px;
      max-width: 70%;
      margin: auto;
    }
    &__sub {
      color: #a4a4a4;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.7;
      letter-spacing: 1px;
      max-width: 60%;
      margin: 30px auto 0;
      padding-top: 30px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
        height: 1px;
        background: $grey;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .about-page {
    &--header {
      &__sub {
        color: $black;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.57;
        letter-spacing: 1.4px;
      }
    }
    &--text {
      color: $black;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 1.4px;
      margin: 0;
    }
    &--sidebar {
      background: $light-grey;
      padding: 50px 30px;
      margin: 50px 0;
      ul {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        li {
          flex: 1 0 100%;
          margin-bottom: 20px;
        }
      }
    }
    &--footer {
      margin-top: 0;
      padding: 50px 0;
      text-align: center;
      border-top: 4px solid $red;
      border-bottom: 4px solid $red;
      &__main {
        color: $black;
        font-size: 40px;
        line-height: 1.4;
        letter-spacing: 2px;
        max-width: 100%;
        margin: auto;
      }
      &__sub {
        color: #a4a4a4;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.7;
        letter-spacing: 1px;
        max-width: 100%;
        margin: 30px auto 0;
        padding-top: 30px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 50%;
          height: 1px;
          background: $grey;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .about-page {
    &--header {
      &__sub {
        color: $black;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.57;
        letter-spacing: 1.4px;
      }
    }
    &--text {
      color: $black;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 1.4px;
      margin: 0;
    }
    &--sidebar {
      background: $light-grey;
      padding: 30px 30px;
      margin: 30px 0;
      ul {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        li {
          flex: 1 0 100%;
          margin-bottom: 20px;
        }
      }
    }
    &--footer {
      margin-top: 0;
      padding: 50px 0;
      text-align: center;
      border-top: 4px solid $red;
      border-bottom: 4px solid $red;
      &__main {
        color: $black;
        font-size: 20px;
        line-height: 1.5;
        letter-spacing: 2px;
        max-width: 100%;
        margin: auto;
      }
      &__sub {
        color: #a4a4a4;
        font-size: $basic-font-size;
        font-weight: 400;
        line-height: 1.7;
        letter-spacing: 1px;
        max-width: 100%;
        margin: 25px auto 0;
        padding-top: 25px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 50%;
          height: 1px;
          background: $grey;
        }
      }
    }
  }
}
</style>
