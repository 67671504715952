<template>
  <div class="rb-page feedback">
    <div class="container feedback-wrap">
      <div class="white-box">
        <div class="row">
          <div class="col-lg-8">
            <div class="feedback-box">
              <div class="feedback-block">
                <h1 class="rb-page--header">
                  {{ $t('feedback.title') }}
                </h1>
                <div v-if="notification" class="alert alert-info">
                  <p>{{ $t('feedback.submitMsg') }}</p>
                </div>
                <div class="feedback-form">
                  <form ref="form" @submit.prevent="submitFeedback">
                    <div class="form-group" :class="{ 'has-error': errs.first('feedbackEmail') }">
                      <input
                        id="email"
                        v-model="email"
                        name="feedbackEmail"
                        :placeholder="$t('feedback.email')"
                        type="text"
                        class="form-control"
                        @input="notification = false"
                      />
                      <div v-show="errs.has('feedbackEmail')" class="error">
                        <span>{{ errs.first('feedbackEmail') }}</span>
                      </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': errs.first('feedbackSubject') }">
                      <input
                        id="subject"
                        v-model="subject"
                        type="text"
                        :placeholder="$t('mail.placeholders.subject')"
                        name="feedbackSubject"
                        class="form-control"
                        @input="notification = false"
                      />
                      <div v-show="errs.has('feedbackSubject')" class="error">
                        <span>{{ errs.first('feedbackSubject') }}</span>
                      </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': errs.first('feedbackMessage') }">
                      <content-editable
                        v-model="message"
                        :name="'feedbackMessage'"
                        :clear="clear"
                        :placeholder="$t('mail.placeholders.message')"
                        class="message form-control"
                        @input="notification = false"
                        @clearText="clearText = $event"
                      />
                      <div v-show="errs.has('feedbackMessage')" class="error">
                        <span>{{ errs.first('feedbackMessage') }}</span>
                      </div>
                    </div>
                    <div class="form-group" :class="{ 'has-error': errs.first('feedbackCaptcha') }">
                      <vue-recaptcha
                        v-if="siteSettings.google_recaptcha_public_key"
                        ref="recaptcha"
                        class="g-recaptcha"
                        name="feedbackCaptcha"
                        :load-recaptcha-script="true"
                        :sitekey="siteSettings.google_recaptcha_public_key"
                        @verify="onVerify"
                        @render="scaleCaptcha"
                        @expired="onExpired"
                      />
                      <div v-show="errs.has('feedbackCaptcha')" class="error">
                        <span>{{ errs.first('feedbackCaptcha') }}</span>
                      </div>
                    </div>
                    <p v-show="files.length" class="text-muted text-small">
                      {{ $t('feedback.limitFiles', [maxFilesCount]) }}
                    </p>
                    <div class="bottom-block">
                      <div class="item-col">
                        <rb-button type="submit" class="btn-black" :disabled="errs.has('email')">
                          {{ $t('feedback.submit') }}
                        </rb-button>
                      </div>
                      <div class="item-col">
                        <div v-if="!maxFiles" class="attach-box">
                          <label for="file-upload" class="btn" title="Upload photo">
                            <span>
                              <span class="rbi-clip" />
                              <span>{{ $t('feedback.attach') }}</span>
                            </span>
                          </label>
                          <input
                            id="file-upload"
                            class="upload-input"
                            type="file"
                            multiple
                            @change="upload($event)"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-show="filePreview" class="attach-file">
                      <ul class="preview-list">
                        <li v-for="(file, i) in filePreview" :key="`file-${i}`">
                          <span>{{ file | cut }}</span>
                          <span @click="deleteFile(i)">
                            <span class="rbi-close" />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 second-block">
            <div class="feedback--sidebar">
              <div class="about-page--sidebar__inner">
                <ul class="list-unstyled">
                  <li class="set-flex">
                    <span class="icon rbi-message" />
                    <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
                  </li>
                  <li class="set-flex">
                    <span class="icon rbi-phone" />
                    <a :href="`tel:${sitePhoneWithotSpace}`">{{ sitePhone }}</a>
                  </li>
                  <li class="set-flex">
                    <span class="icon rbi-location" />
                    <span>{{ siteSettings.company_name }}, {{ siteSettings.company_address }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col feedback-footer">
            <div class="heading">
              <h2>{{ $t('feedback.heading') }}</h2>
            </div>
            <ul class="list-unstyled socials">
              <li class="social-link">
                <a :href="socialLink.facebook" target="_blank" class="social-icon rbi-facebook" />
              </li>
              <li class="social-link">
                <a :href="socialLink.twitter" target="_blank" class="social-icon rbi-twitter" />
              </li>
              <li class="social-link">
                <a :href="socialLink.youtube" target="_blank" class="social-icon rbi-youtube" />
              </li>
              <li class="social-link">
                <a
                  :href="socialLink.instagram"
                  target="_blank"
                  class="social-icon rbi-instagram-new"
                />
              </li>
              <li class="social-link">
                <a :href="socialLink.pinterest" target="_blank" class="social-icon rbi-pinterest" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <toasts />
    <alerts />
    <reg-modal />
    <confirm-restore-profile v-if="restoreModal.open" />
  </div>
</template>
<style></style>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { Validator } from 'vee-validate';
import { socialLink } from '../../../config/config';

import Toasts from '../../Authorized/Toast.vue';
import Alerts from '../../Authorized/Alerts.vue';
import RbButton from '../../Button';

import RegModal from '../modals/RegisterModal.vue';
import ConfirmRestoreProfile from '../modals/ConfirmRestoreProfile.vue';
import ContentEditable from '../../Authorized/parts/ContentEditable.vue';
import { LOAD_PROFILE } from '../../../mixins/types/static';

export default {
  feedbackValidator: null,
  filters: {
    /**
     * Обрезка названий файлов до 15 символов
     */
    cut(v) {
      if (v.length > 15) {
        const tmp = v.split('.');
        return `${v.slice(0, 15)}..${tmp.pop()}`;
      }
      return v;
    },
  },
  components: {
    VueRecaptcha,
    Toasts,
    Alerts,
    ContentEditable,
    RegModal,
    RbButton,
    ConfirmRestoreProfile,
  },
  mixins: [LOAD_PROFILE],
  props: {
    customStyles: Boolean,
  },
  data() {
    return {
      socialLink,
      imageData: '',
      file: {},
      clear: 0,
      error: '',
      notification: false,
      alreadySent: false,
      email: '',
      subject: '',
      message: '',
      clearText: '',
      files: [],
      errs: null,
      sendMail: [],
      isSubmitted: false,
      maxFileSize: 20 * 1024 * 1024,
      maxFilesCount: 4,
      captcha: '',
    };
  },
  computed: {
    restoreModal() {
      return this.$store.getters.restoreProfileModal;
    },
    siteSettings() {
      return this.$store.getters.siteSettings;
    },
    filePreview() {
      return this.files.map((item) => item.fileName);
    },
    sex() {
      return this.$store.getters.sex;
    },
    isAuthorized() {
      return !!window.localStorage['access-token'];
    },
    maxFiles() {
      return this.files.length >= this.maxFilesCount;
    },
    supportEmail() {
      return this.$store.getters.siteSettings.support_email
    },
    sitePhone() {
      return this.$store.getters.siteSettings.site_phone
    },
    sitePhoneWithotSpace() {
      return this.$store.getters.siteSettings.site_phone.replace(/\s/g, '');
    },
  },
  watch: {
    captcha(v) {
      this.feedbackValidator.validate('feedbackCaptcha', v).catch(() => {});
    },
    /**
     * Валидация текста сообщения
     */
    clearText(v) {
      this.feedbackValidator.validate('feedbackMessage', v).catch(() => {});
    },
    /**
     * Валидация темы сообщения
     */
    subject(v) {
      this.feedbackValidator.validate('feedbackSubject', v).catch(() => {});
    },
    /**
     * Валидация email`а отправителя
     */
    email(v) {
      this.feedbackValidator.validate('feedbackEmail', v).catch(() => {});
    },
  },
  created() {
    /**
     * Инициализация плагина с валидацией формы
     */
    this.feedbackValidator = new Validator();
    this.feedbackValidator.attach('feedbackSubject', 'required|min:5|max:250', {
      prettyName: this.$t('feedback.subject'),
    });
    this.feedbackValidator.attach('feedbackMessage', 'required|min:50|max:5000', {
      prettyName: this.$t('feedback.msg'),
    });
    this.feedbackValidator.attach('feedbackEmail', 'required|email', {
      prettyName: this.$t('feedback.email'),
    });
    this.feedbackValidator.attach('feedbackCaptcha', 'required', {
      prettyName: 'Captcha',
    });
    this.$set(this, 'errs', this.feedbackValidator.errorBag);
    this.scaleCaptcha();
    window.addEventListener('resize', this.scaleCaptcha);
  },
  mounted() {
    document.title = this.$t('feedback.title');
  },
  methods: {
    /**
     * Верификация капчи
     */
    onVerify(response) {
      this.captcha = response;
    },
    /**
     * Очистка при ошибке
     */
    onExpired() {
      this.captcha = '';
    },
    /**
     * Обнулить состояние капчи
     */
    resetRecaptcha() {
      this.captcha = '';
      this.$refs.recaptcha.reset();
    },
    /**
     * очистка состояния компонента
     */
    clearData() {
      this.email = '';
      this.subject = '';
      this.message = '';
      this.clearText = '';
      this.clear++;
      this.files = [];
      this.resetRecaptcha();

      const self = this;
      setTimeout(() => {
        self.errs.clear();
      }, 0);
    },
    /**
     * Загрузка файлов с input[type=file] и их валидация
     */
    upload(e) {
      if (!this.maxFiles) {
        const files = [].slice
          .call(e.target.files)
          .filter((i) => {
            if (i.size <= this.maxFileSize) return true;
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.incorrectFileSize', [
                i.name,
                `${this.maxFileSize / 1024 / 1024} Mb`,
              ]),
              info: 'max file size reached',
            });
            return false;
          }, this)
          .map((i) => ({
            fileName: i.name,
            _file: i,
          }));
        this.files = this.files.concat(files.slice(0, this.maxFilesCount - this.files.length));
        this.$refs.form.reset();
      }
    },
    /**
     * Удалить файл с массива
     */
    deleteFile(i) {
      this.files.splice(i, 1);
    },
    /**
     * Отправить гостевой фидбек
     */
    sendFeedbackGuest() {
      const formatFiles = this.files.map((i) => i._file);
      const formData = new FormData();
      formData.append('subject', this.subject);
      formData.append('email', this.email);
      formData.append('message', this.message);
      formData.append('captcha', this.captcha);
      formatFiles.forEach((i) => {
        formData.append('images[]', i);
      });
      this.$http.post('v1/feedback/make', formData).then(
        (r) => {
          if (r.body.status) {
            this.alreadySent = true;
            this.notification = true;
            this.email = '';
            this.clearData();
          } else {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.requestError'),
              info: 'send feedback',
            });
          }
        },
        () => {
          this.$store.commit('addAlert', {
            type: 'error',
            text: this.$t('alerts.requestError'),
            info: 'send feedback',
          });
        }
      );
    },
    /**
     * Отправить фидбек от зарегистрированных пользователей
     */
    sendFeedbackUser() {
      const formatFiles = this.files.map((i) => i._file);
      const formData = new FormData();
      formData.append('access_token', window.localStorage['access-token']);
      formData.append('subject', this.subject);
      formData.append('email', this.email);
      formData.append('message', this.message);
      formData.append('captcha', this.captcha);
      formatFiles.forEach((i) => {
        formData.append('images[]', i);
      });
      this.$http.post('v1/feedback/user-make', formData).then(
        (r) => {
          if (r.body.status) {
            this.alreadySent = true;
            this.notification = true;
            this.email = '';
            this.clearData();
          } else {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.requestError'),
              info: 'send feedback',
            });
          }
        },
        () => {
          this.$store.commit('addAlert', {
            type: 'error',
            text: this.$t('alerts.requestError'),
            info: 'send feedback',
          });
        }
      );
    },
    /**
     * Начать отправку фидбека. Валидация и выбор какой роут использовать
     */
    submitFeedback() {
      this.feedbackValidator
        .validateAll({
          feedbackSubject: this.subject,
          feedbackMessage: this.clearText,
          feedbackEmail: this.email,
          feedbackCaptcha: this.captcha,
        })
        .then((success) => {
          if (success) {
            if (this.isAuthorized) {
              this.sendFeedbackUser();
            } else {
              this.sendFeedbackGuest();
            }
          }
        })
        .catch(() => {});
    },
    scaleCaptcha() {
      const reCaptchaWidth = 304;
      if (this.$refs.recaptcha) {
        const rec = this.$refs.recaptcha.$el;
        // eslint-disable-next-line no-undef
        const containerWidth = $('.feedback-form').width();
        // Only scale the reCAPTCHA if it won't fit
        // inside the container
        if (reCaptchaWidth > containerWidth) {
          // Calculate the scale
          const captchaScale = containerWidth / reCaptchaWidth;
          // Apply the transformation
          rec.style.transform = `scale(${captchaScale})`;
          rec.style.transformOrigin = '0 0 0';
        } else {
          rec.style.transform = 'scale(1)';
          rec.style.transformOrigin = '0 0 0';
        }
      }
    },
  },
  /* beforeRouteEnter( from, to, next ){
         document.body.classList.add( 'feedback-body' );
         next( vm => {
         let elements = document.getElementsByClassName( 'index-navbar' )[ 0 ] || document.getElementsByClassName( 'user-navbar' )[ 0 ];
         elements.classList.add( 'relative' )
         } )
         },
         beforeRouteLeave( from, to, next ){
         let elements = document.getElementsByClassName( 'index-navbar' )[ 0 ] || document.getElementsByClassName( 'user-navbar' )[ 0 ];
         document.body.classList.remove( 'feedback-body' );
         elements.classList.remove( 'relative' )
         next();
         } */
};
</script>

<style scoped lang="scss">
@import '../../../assets/scss/vars';

.feedback {
  &-block {
    &__heading {
      color: $black;
      font-size: 26px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 1.3px;

      &::first-letter {
        color: $red;
      }
    }

    .bottom-block {
      display: flex;
      width: 100%;
      max-width: 304px;
      justify-content: space-between;
      margin-bottom: 20px;
      margin-top: 30px;

      .item-col {
        width: 45%;
      }

      .btn {
        width: 100%;
        padding: 13px 0;
      }
    }

    .attach-box {
      .upload-input {
        text-indent: -9999px;
        display: none;
        visibility: hidden;
        opacity: 0;
      }

      .btn {
        span {
          &:first-child {
            display: flex;
            align-items: center;
          }
        }
      }
      .rbi-clip {
        font-size: 20px;
        margin-right: 5px;
      }
    }

    .attach-file {
      .preview-list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: flex;
          align-content: center;

          span {
            &:last-child {
              margin-left: 5px;
              display: flex;
              align-items: center;
              cursor: pointer;

              &:hover {
                color: $red;
              }
            }
          }
        }
      }
    }
  }

  &--sidebar {
    background: $light-grey;
    padding: 44px 35px;

    ul {
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 35px;
        color: $black;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: 1.4px;

        &:last-child {
          margin-bottom: 0;
        }

        .icon {
          margin-right: 15px;
          font-size: 21px;
        }

        a {
          color: $red;
        }
      }
    }
  }

  .form-group {
    margin-bottom: 20px;

    &:last-child {
    }

    .form-control {
      width: 100%;

      &.message {
        margin-top: 30px;
        border: 1px solid #d9d9d9;

        + .error {
          margin-bottom: -20px;
        }
      }
    }
  }

  &-footer {
    margin-top: 50px;
    .heading {
      overflow: hidden;

      h2 {
        color: $black;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.3;
        letter-spacing: 1px;
        position: relative;
        background: $white;
        margin-bottom: 25px;

        &::first-letter {
          color: $red;
        }

        &:after {
          content: '';
          display: inline-block;
          height: 1px;
          vertical-align: middle;
          width: 100%;
          margin-right: -100%;
          margin-left: 10px;
          background: #d9d9d9;
        }
      }
    }
    .socials {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        margin-right: 35px;
        &:last-child {
          margin-right: 0;
          font-size: 16px;
        }
        a {
          color: $black;
          text-decoration: none;
          &:hover {
            color: $red;
          }
        }
        .rbi {
          &-youtube {
            font-size: 22px;
          }
          &-google-plus {
            font-size: 22px;
          }
        }
      }
    }
  }
}

.error {
  color: red;
}

@media screen and (max-width: 991px) {
  .feedback {
    &-block {
      &__heading {
        color: $black;
        font-size: 20px;

        &::first-letter {
          color: $red;
        }
      }
    }
    &--sidebar {
      background: $light-grey;
      padding: 50px 30px;
      margin: 50px 0;
      ul {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        li {
          flex: 1 0 100%;
          margin-bottom: 20px;
        }
      }
    }

    &-footer {
      margin-top: 0px;
      .heading {
        overflow: hidden;

        h2 {
          color: $black;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.3;
          letter-spacing: 1px;
          position: relative;
          background: $white;
          margin-bottom: 25px;

          &::first-letter {
            color: $red;
          }

          &:after {
            content: '';
            display: inline-block;
            height: 1px;
            vertical-align: middle;
            width: 100%;
            margin-right: -100%;
            margin-left: 10px;
            background: #d9d9d9;
          }
        }
      }
      .socials {
        display: flex;
        list-style: none;

        padding: 0;
        margin: 0;
        li {
          margin-right: 35px;
          &:last-child {
            margin-right: 0;
            font-size: 16px;
          }
          a {
            color: $black;
            text-decoration: none;
            &:hover {
              color: $red;
            }
          }
          .rbi {
            &-youtube {
              font-size: 22px;
            }
            &-google-plus {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .feedback {
    &-block {
      &__heading {
        color: $black;
        font-size: 20px;

        &::first-letter {
          color: $red;
        }
      }
    }
    &--sidebar {
      background: $light-grey;
      padding: 30px 30px;
      margin: 30px 0;
      ul {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        li {
          flex: 1 0 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
}
p,
.rb-page .ul-styled li {
  margin-bottom: 0.5em;
}
</style>
