<template>
  <div class="rb-page faq">
    <div
      class="container feedback-wrap"
      :class="{ unselectable: isGuest }"
      @contextmenu="interact"
      @copy="interact"
      @cut="interact"
    >
      <div class="white-box">
        <div class="row">
          <div class="col-12">
            <h1 class="rb-page--header">
              {{ $t('shared.menu.faq') }}
            </h1>
            <p class="faq--header__sub">
              {{ subTitle }}
            </p>
            <ol class="rb-page--list rb-page--list__main list-unstyled">
              <li
                v-for="(faq, i) in faqLists"
                :key="faq.sectionName"
                class=""
                :class="{ open: faq.active }"
              >
                <div class="section-name-wrap">
                  <span class="increment" />
                  <h2
                    class="faq-section-name"
                    :class="{ open: faq.active }"
                    @click="toggleAccordion($event, i)"
                  >
                    {{ faq.sectionName }}
                  </h2>
                </div>
                <transition-height>
                  <div v-show="faq.active" class="rb-page-faq">
                    <div
                      v-for="question in faq.questions"
                      :key="question.questionName"
                      class="faq-item"
                    >
                      <h3 class="faq-item__title">
                        {{ question.questionName }}
                      </h3>
                      <div class="faq-item__answer">
                        <dynamicContent :text="question.textHtml" />
                      </div>
                    </div>
                  </div>
                </transition-height>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <toasts />
    <reg-modal />
    <confirm-restore-profile v-if="restoreModal.open" />
  </div>
</template>

<script>
import dynamicContent from '../dynamicContent.vue';
import TransitionHeight from '../parts/TransitionHeight';

import Toasts from '../../Authorized/Toast.vue';
import RegModal from '../modals/RegisterModal.vue';
import ConfirmRestoreProfile from '../modals/ConfirmRestoreProfile.vue';
import { LOAD_PROFILE, SELECTION } from '../../../mixins/types/static';

export default {
  components: {
    Toasts,
    RegModal,
    ConfirmRestoreProfile,
    TransitionHeight,
    dynamicContent,
  },
  mixins: [LOAD_PROFILE, SELECTION],
  data() {
    return {
      subTitleMan:
        'This page displays the most frequently asked questions from our users. All answers shown on this page are indicative and have advisory nature and do not have any legal force.',
      subTitleWoman:
        'На этой странице отображаются наиболее часто задаваемые вопросы от наших пользователей. Все ответы, представленные на этой странице, носят рекомендательный характер и не имеют юридической силы.',
      faqListsMan: [
        // 1
        {
          active: true,
          sectionName: 'Registration and authorization:',
          questions: [
            // 1.1
            {
              questionName:
                'I want to read the Service Agreement and other documents, where can I find them?',
              textHtml: `
                <div>
                  <p>You can find any documents at:</p>
                  <ul class="ul-styled">
                    <li>Site Terms of Use Agreement, at link:
                        <router-link :to="{name: 'terms'}">link</router-link>
                    </li>
                    <li>Site Refund Policy, at link:
                        <router-link :to="{name: 'privacy'}">link</router-link>
                    </li>
                    <li>Site Privacy Policy, at link:
                        <router-link :to="{name: 'antiscam'}">link</router-link>
                    </li>
                    <li>Site Anti scam-policy, at link:
                        <router-link :to="{name: 'refund'}">link</router-link>
                    </li>
                    <li>Site Cookie Policy, at link: <router-link :to="{name: 'cookie'}">link</router-link></li>
                  </ul>
                </div>
                `,
            },
            // 1.2
            {
              questionName: 'Why should I register on the service?',
              textHtml: `
                <div>
                  <p>Our website administration needs to
                  identify You as a particular user in order
                  You could perform actions on our service and preserve Your results.
                  Example: when You have no registration on our site You can’t create a list of Your favorites,
                  and we can’t do this instead of You</p>
                </div>
                `,
            },
            // 1.3
            {
              questionName: 'What can I do without registering?',
              textHtml: `
                <div>
                  <p>
                    All the necessary information is available on the website for Your review. You are able to observe information on the main page of the service, search for girls to chat with, view the list of girls, their profiles and photos. Furthermore, You can send Your requests and proposals to the support service.
                  </p>
                </div>
                `,
            },
            // 1.4
            {
              questionName: 'Is the registration paid on the website?',
              textHtml: `
                <div>
                  <p>Registration on the service is absolutely free of charge.</p>
                </div>
                `,
            },
            // 1.5
            {
              questionName: 'What to do in case I forgot password from my account?',
              textHtml: `
                <div>
                <p>For this purpose, please, use the password recovery form,
                that is easily to find in the authorization block. In addition,
                You can use the feedback form addressing this issue to us. </p>
                </div>
                `,
            },
            // 1.6
            {
              questionName:
                'What to do if I want to change the e-mail address to which my account is registered?',
              textHtml: `
                <div>
                  <p>Write a letter to the support service using the feedback form. In Your letter, enter the data confirming that You own the account on our service. We will examine this data when receive the letter, in case this information is true, we will contact You.</p>
                  <p><strong>Important!</strong> After registration, the e-mail which You have linked Your account with is impossible to change.</p>
                </div>
                `,
            },
            // 1.7
            {
              questionName: 'My account was stolen, what should I do?',
              textHtml: `<div>
                  <p>It is necessary to write a letter about this to the support service
                  of our site via the feedback form or by e-mail. Once we receive Your letter,
                  we will verify all the information provided by You and give You the detailed
                  instructions on Your next actions.
                  </p>
                </div>
                `,
            },
            // 1.8
            {
              questionName: 'How can I delete my account?',
              textHtml: `
                <div>
                  <p>Write a letter to the support service,
                  using the feedback form or by e-mail.
                  In Your e-mail attach video-message with intention to delete
                  Your account and the reason for removal. You have indicate the ID
                  of your account and e-mail of registration. When we receive the e-mail,
                  we will contact You in order to confirm the removal of Your account.</p>
                </div>
                `,
            },
          ],
        },
        // 2
        {
          active: false,
          sectionName: 'Services and payments:',
          questions: [
            // 2.1
            {
              questionName: 'What to do after registration?',
              textHtml: `<div>
                  <p>To make Your profile looks more attractive
                  in the eyes of girls, You can fill out information about
                  Yourself and upload some photos. After that You can search
                  for girls and women profiles or lists of profiles recommended
                  for viewing. When You find a girl or woman who interests You,
                  save her profile in bookmarks or add to favorites. You can send her a wink,
                  this action expresses a sign of attention, and maybe soon she will write to You.</p>
                </div>`,
            },
            // 2.2
            {
              questionName: 'What information can I provide in my profile?',
              textHtml: `<div>
                  <p>
                While using our service, the only information required to provide from You is the e-mail address and password. You give us this information during Your registration. At Your own discretion You can disclose the rest of the information. In order to perform this, You should click on the “edit” buttons in Your profile. Anyway, please, pay Your attention that girls hate liars, so do not include false information in Your profile.
                </p>
                </div>`,
            },
            // 2.3
            {
              questionName: 'Do I have to upload my photos?',
              textHtml: `<div>
                    <p>	This action is not mandatory, but when You set a nice picture as Your avatar Your profile becomes more attractive. It is worth to add 3-4 of Your best photos to a public album. On our website it is allowed to upload photos of JPEG and PNG format, with a resolution of at least 720px x 720px and not more than 3072px x 1620px, and also no larger than 2 Mb.</p>
                    <p>	The administrators of the website is able to delete Your photos from public album, if they contain pornographic and improper material or indecent exposure.</p>
                </div>`,
            },
            // 2.4
            {
              questionName: 'Does anyone else see my private photos? ',
              textHtml: `<div>
                <p>No one can see Your personal album except You. Such private album is necessary so that You can upload photos that are out of reach to all users. You are free to send these photos via a letter to the girl You have chosen.</p>
                <p>In case, if the girl, to whom You sent these photos, found them offensive or such those could violate her rights, she will file a complaint to the administration, so we will ask You to delete this material to avoid penalties.</p>
                </div>`,
            },
            // 2.5
            {
              questionName: 'Why should I wink at girls?',
              textHtml: `<div>
                <p>You can send to girl only one wink at a day. Every time You send a wink to her, the girl sees a notification about it. You also appear on the list of her winks. In this manner You attract her attention.</p>
                </div>`,
            },
            // 2.6
            {
              questionName: 'Why do I need a list of favorites and bookmarks?',
              textHtml: `<div>
<p>You can add a girl to Your list of favorites, or the girl herself can send a request to add her to Your favorites. If You confirm her request, you will share Your favorites lists between each other, this is possible by mutual consent only. We suggest You to add girls with whom you often communicate to this list or You may add those girls who interest You more than others. In case You decline a girl’s request or delete her from Your favorites list, she losts right to send You requests to add to favorites.</p>
<p>If You want anonymously add the girl to the bookmarks, please, use the list of bookmarks. We advice You don’t forget to write to Your favorited girl later or visit her profile.</p>
</div>`,
            },
            // 2.7
            {
              questionName: 'Can I see the guests of my profile?',
              textHtml: `<div>
<p>Yes, sure. If You want to see the guests You can easily do this using a corresponding menu section "Guests." This section captures all visitors to Your profile. </p>
</div>`,
            },
            // 2.8
            {
              questionName: 'Can I turn off notifications?',
              textHtml: `<div>
                <p>Sure, it is possible! In the “Settings” You are free to turn off the visual or sound notifications. To perform this, please, click on Your avatar in the upper right corner and select “Settings”.</p>
                <p>Visualization of system notifications is impossible to turn off.</p>
                </div>`,
            },
            // 2.9
            {
              questionName: 'What are the particularities of the service?',
              textHtml: `<div>
                <p>By the use of our service, You can:</p>
                <ul class="ul-styled">
                  <li>Look for girls;</li>
                  <li>Demonstrate signs of attention to girls;</li>
                  <li>Save the lists of favorites and bookmarks;</li>
                  <li>Track Your profile’s guests; </li>
                  <li>Write letters to the girls;</li>
                  <li>Send gifts to girls;</li>
                  <li>Track the most interesting news on the website;</li>
                  <li>Chatting in text chat;</li>
                  <li>Chatting in video chat;</li>
                  <li>Communicate in private chat;</li>
                  <li>Request girls’ contact information.</li>
                </ul>
              </div>`,
            },
            // 2.10
            {
              questionName: 'Which services are paid? And how much do they cost?',
              textHtml: `<div>
              <p>	The list of paid services includes:</p>
              <ul class="ul-styled">
        <li>Writing letters to girls - 10 credits;</li>
        <li>Sending gifts to girls - gift price is indicated in credits at each gift including “to the door” delivery;</li>
        <li>Chatting in text chat - 1 credits;</li>
        <li>Chatting in video chat - 2 credits per minute;</li>
        <li>Communication in private chat - 3 credits per minute;</li>
        <li>Purchase of private photos of the woman user in the chat - 4 credits per photo; </li>
        <li>Request for girls’ contact information - 406 credits.</li>
              </ul>
            </div>`,
            },
            // 2.11
            {
              questionName: 'How can I buy credits?',
              textHtml: `<div>
                   <p>By clicking on the coin image at the top of the website page, You have the ability to buy the virtual currency of our website, which is called “credit”. Once you click on it, You will go to the list of credit packages. You may choose the one that is suitable for You and then click to buy. Right after this You will be transferred to the payment system.</p>
                   <p>Please, note, the more credits You buy at a time, the lower the price of each credit is!</p>
                </div>`,
            },
            // 2.12
            {
              questionName: 'How much does 1 credit cost?',
              textHtml: `<div>
                <p>By mischance, we can not accurately answer this question. Credits are sold both in standard and in promotional packages. The cost of each credits depends on the quantity of credits in the package.</p>
                </div>`,
            },
            // 2.13
            {
              questionName: 'Do girls have "private" photos? How can I view them?',
              textHtml: `<div>
                <p>Of course, every girl on the website has the album with her private photos. In case when You want to see all her photos, You can send her a request or letter and ask if she could provide the photos. </p>
                <p>We advise You, to get acquainted with the girl, talk to her first, try to learn her better and only after this ask for private photos. When the girl sends photos together with  the letter you are able to review it in incoming letters, if she sends photos using chat you can see it in the folder “purchased”.  </p>
                </div>`,
            },
            // 2.14
            {
              questionName: 'What if I write a letter to a girl and she does not answer?',
              textHtml: `<div>
<p>	The administration of our service does not represent or warrant that the girl will reply to Your letter. At first, try to be interesting to this girl, send her a wink and invite her to chat. </p>
</div>`,
            },
            // 2.15
            {
              questionName: 'Can I use the chat if I do not have a camera or microphone?',
              textHtml: `<div>
<p>	Sure, You can. You can easily use all types of chats. You should pay Your attention, that during chatting in private chat with no camera, the girl will not be able to see You.</p>
</div>`,
            },
            // 2.16
            {
              questionName: 'What is the difference between text, video and private chats?',
              textHtml: `<div>
                <p>In the text chat, You can chat using text and smiles only.
                In the video chat You can additionally view the video from the girl's camera.
                Private chat provides You more opportunities: You can turn on the camera
                and talk with each other “face to face”. The private chat window is different
                from the other two chats allowing You to enjoy the communication with the girl
                You chose as comfortable as possible. In addition, it should be borne in mind that
                the more functions a chat has, the higher its cost.</p>
                </div>`,
            },
            // 2.17
            {
              questionName: 'Can I send my photos to girls?',
              textHtml: `<div>
                  <p>No, our service does not provide such opportunity. All Your photos are available for girls in your albums.</p>
                </div>`,
            },
            // 2.18
            {
              questionName: 'Can I receive photos from the girls?',
              textHtml: `<div>
                  <p>Yes, of course! The cost is 4 credits per each photo. </p>
                </div>`,
            },
            // 2.19
            {
              questionName:
                'Where can I view the photo received and opened by me from women or girls?',
              textHtml: `<div>
<p>You are able to view all photos, received and opened by You in the folder “Purchased”.</p>
</div>`,
            },
            // 2.20
            {
              questionName: 'Is there any history of messages in the chat?',
              textHtml: `<div>
<p>	The Chat settings allow to record Your correspondence during the current session of the browser window. In case when You close the browser window or the browser itself, the message history will be deleted. At the same time, every girl You had a conversation to is stored in the Your Contacts list.</p>
</div>`,
            },
            // 2.21
            {
              questionName: 'If I close the chat window, will it stop?',
              textHtml: `<div>
                   <p>After the browser is closed, the chat will stop, the credits will no longer be counted, and the correspondence history will be deleted.</p>
                   <p>Please, pay Your attention, that at the beginning of every minute of the chat the credits are written off from Your account. So if You close the window when the minute starts, this minute is considered to be paid.</p>
                </div>`,
            },
            // 2.22
            {
              questionName: 'The video from the video chat is a record, isn’t it?',
              textHtml: `<div>
                <p>	No, the video image is being transmitted in real time from the girl’s camera.</p>
                </div>`,
            },
            // 2.23
            {
              questionName: 'How does the gift I order on the website get to the girl?',
              textHtml: `<div>
                  <p>As soon as You have ordered a gift for a girl, Your order will be processed. We always check whether the gift You have chosen is available in the country and city where the girl lives, then we fulfill the gift order and we track the entire delivery process, starting from the gift wrapping in the warehouse to the door delivery. After completing the delivery, we will contact the girl to make sure that the gift was delivered to her, that she is in a good mood and she is pleased with this gift. When the girl receives a gift, she will thank You for it!</p>
                </div>`,
            },
            // 2.24
            {
              questionName: 'Can I receive the gift from a girl on the web site?',
              textHtml: `<div>
<p>Yes, sure! You can receive virtual gifts from the girls.</p>
</div>`,
            },
            // 2.25
            {
              questionName:
                'Is it possible to give a gift which is not on the list on the service?',
              textHtml: `<div>
<p>	Unfortunately, it is not possible. We work with reliable suppliers and we can only deliver the gifts that are available in the region where the girl lives. If you want to present the gift that is out of the list, You can send a request about it using the feedback form or by e-mail, and we will give careful thought on Your demand. Perhaps we will add the gifts that You have offered to the list in future</p>
</div>`,
            },
            // 2.26
            {
              questionName:
                "What if after buying the girl's contacts, they turn out to be incorrect?",
              textHtml: `<div>
                  <p>	We examine the contact information details of every girl during registration process, so wrong data is not provided by us. In addition, we check the contacts before every time You purchase it. </p>
                </div>`,
            },
            // 2.27
            {
              questionName: 'Does the girl know that I bought her contacts?',
              textHtml: `<div>
<p>Yes, sure! We always inform the girls that someone from man-users have bought her contact information, moreover, we tell the girls who was that person who has purchased her contacts. All information transferred to You is based on her approval and legislation on the protection of personal data of the country of her residence.</p>
</div>`,
            },
            // 2.28
            {
              questionName: 'What payment methods and payment systems does the service support?',
              textHtml: `<div>
<p>On our website, all payments are made by means of Multicards service and Segpay service. You can reach for more detailed information about payment systems supported by this service, if You address their website.</p>
</div>`,
            },
            // 2.29
            {
              questionName: 'What to do if I have difficulty when buying credits?',
              textHtml: `<div>
<p>You can describe this issue in written using the feedback form or e-mail. Payment issues are always prioritized, so once we receive Your letter, we will contact You as soon as possible!</p>
</div>`,
            },
            // 2.30
            {
              questionName: 'How long are the credits accrued to the account?',
              textHtml: `<div>
<p>Once the payment service confirmed Your payment, credits are accrued immediately after such confirmation.</p>
</div>`,
            },
            // 2.31
            {
              questionName: 'Can I return my money?',
              textHtml: `<div>
                  <p>	The license agreement provides all cases, in which we can refund Your invested amounts.</p>
                </div>`,
            },
          ],
        },
        // 3
        {
          active: false,
          sectionName: 'Communication and relationships between participants on the site:',
          questions: [
            // 3.1
            {
              questionName: 'Are there participants under the age of 18 on the service?',
              textHtml: `<div>
<p>You can describe this issue in written using the feedback form or e-mail. Payment issues are always prioritized, so once we receive Your letter, we will contact You as soon as possible!</p>
</div>`,
            },
            // 3.2
            {
              questionName: 'Are the girls on Your website real?',
              textHtml: `<div>
                <p>Yes, of course! All the participants at our site are real girls, because each profile is veryfied and approved by the administration of our website.  All girls, whose contacts You can buy, are realistic. Moreover, you can easily meet them!</p>
                </div>`,
            },
            // 3.3
            {
              questionName: 'Do the girls on this website know English? ',
              textHtml: `<div>
                <p>Not all girls speak English fluently. But most of the girls were studying English at school. </p>
                <p>The website has a built-in automatic translator that helps girls communicate with You. Our website can assist You in hiring professional translator in order You will be sure to understand every word of Your favorites. The level of particular language knowledge is noted in her profile.</p>
                </div>`,
            },
            // 3.4
            {
              questionName:
                'I was studying Russian language. Can I communicate with a girl in Russian?',
              textHtml: `<div>
<p>Yes, it is possible! Our site supports the input of Cyrillic and Latin letters. </p>
</div>`,
            },
            // 3.5
            {
              questionName:
                'Can the information about the girl indicated on her profile be untrue?',
              textHtml: `<div>
<p>Under no circumstances! The rules of our website establish passing the interview for every girl before registering, they are also obliged to provide evidence of the data they provide.</p>
<p>The information about girls’ appearance could be the only exception, because, as we know, they always change color or length of their hair.</p>
</div>`,
            },
            // 3.6
            {
              questionName: 'What to do if I doubt the girl’s honesty?',
              textHtml: `<div>
                <p>Please, feel free to inform the support service of our website on this situation via the feedback form or by e-mail. After receiving Your letter, we will verify all the information provided, and if the information is confirmed, we will impose penalties to that girl.</p>
                <p>The license agreement deals with the issue of returning the money spent.</p>
                </div>`,
            },
            // 3.7
            {
              questionName: 'What to do if a girl asked to send her money?',
              textHtml: `<div>
<p> If the girl asks or demands to send her money, do not do it under no circumstances! Only scammers act this way. Don’t forget to write a letter about it to the support service of our website using the feedback form or by e-mail. Once we receive Your letter, we will verify all the information provided, and in case the information is confirmed, we will impose penalties to that girl.</p>
</div>`,
            },
            // 3.8
            {
              questionName: 'Can I meet with a girl?',
              textHtml: `<div>
<p>Yes, sure! After you purchase her contact information, please, feel free to write a letter about Your desire to the support team of our website using the feedback form or by e-mail. After receiving the letter from You, we will contact You in order to get more information from You. Then we will assist with arrangements of Your future trip to the girl or the girl's trip to You.</p>
<p>You must remember, that meetings are possible by mutual consent of the parties only. Before taking such a serious step, we suggest You to discuss with Your beloved one Your intention to meet her as well as the details of Your arrival.</p>
</div>`,
            },
          ],
        },
        // 4
        {
          active: false,
          sectionName: 'Technical issues:',
          questions: [
            // 4.1
            {
              questionName: 'What are the system requirements for the website?',
              textHtml: `<div>
                  <p>The necessary connection speed is up to 50 Mbps and ping 100ms.</p>
                  <p>To use the site it is preferably to use one of the browsers:</p>
                  <ul class="ul-styled">
                    <li>Internet Explorer 10 and higher</li>
                    <li>Opera 15.0 and higher</li>
                    <li>Google Chrome 19.0 and higher</li>
                    <li>Mozilla Firefox 13.0 and higher</li>
                    <li>Safari 6/0 and higher</li>
                  </ul>
                </div>`,
            },
            // 4.2
            {
              questionName: 'What if I found an error on the site?',
              textHtml: `<div>
<p>You should inform the support service of our website on this problem in Your letter using a feedback form or by e-mail. Once we receive letter from You, we will verify all the information provided.</p>
</div>`,
            },
            // 4.3
            {
              questionName: 'I want to call and report an error, how do I call you?',
              textHtml: `<div>
<p>	You can write a letter to the support team of our website requesting our contacts details, using a feedback form or by e-mail. Once we receive letter from You, we will contact You directly by phone or we will send You our contact phone number. </p>
</div>`,
            },
          ],
        },
      ],
      faqListsWoman: [
        // 1
        {
          active: true,
          sectionName: 'Регистрация и авторизация:',
          questions: [
            // 1.1
            {
              questionName:
                'Я хочу прочитать Соглашение об обслуживании и другие документы, где я могу их найти?',
              textHtml: `<div>
<p>Вы можете найти любые документы по адресу:</p>
<ul class="ul-styled">
<li>Соглашение об условиях использования сайта, по ссылке: <router-link :to="{name: 'terms-wu'}">link</router-link>
</li>
<li>Политика конфиденциальности сайта, по ссылке: <router-link :to="{name: 'privacy-wu'}">link</router-link>
</li>
<li>Политика против мошенничества, по ссылке: <router-link :to="{name: 'antiscam'}">link</router-link>
</li>
<li>Политика Cookie, по ссылке: <router-link :to="{name: 'cookie'}">link</router-link>
</li>
</ul>
</div>`,
            },
            // 1.2
            {
              questionName: 'Зачем мне регистрироваться на сервисе?',
              textHtml: `<div>
<p>Администрация нашего сайта должна идентифицировать Вас как отдельного пользователя, чтобы Вы могли выполнять действия с нашим сервисом и сохранять Ваши результаты. Например: если Вы не зарегистрированы на нашем сайте, Вы не имеете возможности создать список Ваших фаворитов, и мы не можем сделать это вместо Вас.</p>
</div>`,
            },
            // 1.3
            {
              questionName: 'Что я могу сделать без регистрации?',
              textHtml: `<div>
<p>На нашем сайте доступна вся необходимая информация для Вашего ознакомления. Вы можете ознакомиться с информацией на главной странице сервиса, искать мужчин для общения, просматривать список мужчин-пользователей, их анкеты и альбомы с фотографиями. Кроме того, Вы можете отправлять Ваши запросы и предложения как администратору Вашего агентства, так и в службу поддержки сайта.</p>
</div>`,
            },
            // 1.4
            {
              questionName: 'Регистрация на сайте платная?',
              textHtml: `<div>
<p>Регистрация на сервисе абсолютно бесплатна.</p>
</div>`,
            },
            // 1.5
            {
              questionName: 'Что делать, если я забыла пароль от своей учетной записи?',
              textHtml: `<div>
<p>Для этого воспользуйтесь формой восстановления пароля, которую легко найти в блоке авторизации. Кроме того, Вы можете обратится на прямую к Вашему администратору сайта, адресовать нам этот вопрос по электронной почте или воспользоваться формой обратной связи.</p>
</div>`,
            },
            // 1.6
            {
              questionName:
                'Что делать, если я хочу изменить адрес электронной почты, на который зарегистрирован мой аккаунт?',
              textHtml: `<div>
<p>Для этих целей обратитесь к Вашему администратору, также Вы можете написать письмо в службу поддержки, используя форму обратной связи. В своем письме введите данные, подтверждающие, что Вы являетесь владелицей учетной записи на нашем сервисе. Мы изучим эти данные при получении письма, в случае если предоставленная информация верна, мы свяжемся с Вами.</p>
<p><strong>Важно!</strong> После регистрации адрес электронной почты, с которым Вы связали свою учетную запись, изменить нельзя.</p>
</div>`,
            },
            // 1.7
            {
              questionName: 'У меня украли аккаунт, что мне делать?',
              textHtml: `<div>
<p>В случае кражи Вашего аккаунта, пожалуйста, обратитесь к администратору Вашего агентства. Одним из способов заявить о данной проблеме является написание письма в службу поддержки нашего сайта на странице Contact Us(для гостей), используя форму обратной связи. Также Вы можете отправить письмо по электронной почте на адрес <a href="mailto:${this.supportEmail}">${this.supportEmail}</a>. Как только мы получим Ваше письмо, мы проверим всю информацию, доверенную Вами, и предоставим Вам подробные инструкции о Ваших дальнейших действиях.</p>
</div>`,
            },
            // 1.8
            {
              questionName: 'Как я могу удалить свой аккаунт?',
              textHtml: `<div>
<p>Обратитесь к админмистратору своего агентства или по электронной почте. Также Вы можете написать письмо в службу поддержки, используя форму обратной связи. В теме письма укажите «Удаление аккаунта», а также приложите видео-обращение с намерением удалить Вашу учетную запись и причину удаления. Вы должны указать ID своей учетной записи и адрес электронной почты регистрации. Когда мы получим электронное письмо, мы свяжемся с Вами, чтобы подтвердить удаление Вашей учетной записи.</p>
</div>`,
            },
          ],
        },
        // 2
        {
          active: false,
          sectionName: 'Услуги сервиса:',
          questions: [
            // 2.1
            {
              questionName: 'Что делать после регистрации?',
              textHtml: `<div>
<p>Чтобы Ваш профиль выглядел более привлекательным в глазах мужчин, Вам следует заполнить информацию о себе и загрузить несколько фотографий (минимум 10 фотографий высокого качества из Вашего портфолио в пристойном виде, в формате jpg или jpeg размером до 2 Mb и разрешением не менее 720х720 и не более 3000х3000 пикселей). После этого Вы можете искать профили мужчин или списки профилей, рекомендованных к просмотру. Найдя интересующего Вас парня или мужчину, сохраните его профиль в закладки или добавьте в фавориты. Вы можете отправить ему подмигивание, это действие выражает знак внимания, и, возможно, скоро он напишет Вам.</p>
</div>`,
            },
            // 2.2
            {
              questionName: 'Какую информацию я могу разместить в своем профиле?',
              textHtml: `<div>
<p>При использовании нашего сервиса Вам нужно внести в своей аккаунт всю основную контактную информацию о Вас, включая Ваше имя, фамилию и отчество, а так же персональный e-mail, контактный номер телефона и данные о Вашей внешности. Поставрайтесь сделать свой аккаунт максимально привлекательным для противоположного пола. Обращаем Ваше внимание, что все данные должны быть правдивые, поэтому не включайте ложную информацию в свой профиль.</p>
</div>`,
            },
            // 2.3
            {
              questionName: 'Нужно ли загружать мои фотографии?',
              textHtml: `<div>
<p>Конечно, это действие является обязательным. Стоит добавить 10 Ваших лучших фото в публичный альбом. На нашем сайте разрешено загружать фотографии в формате JPEG и PNG, с разрешением не менее 720px x 720px и не более 3072px x 1620px, а также не более 2 Мб. Более того, когда Вы устанавливаете красивую картинку в качестве своего аватара, Ваш профиль становится более привлекательным. Администраторы сайта имеют возможность удалить фотографии из общедоступных альбомов, если они содержат порнографический и неподобающий материал или непристойное обнажение.</p>
</div>`,
            },
            // 2.4
            {
              questionName: 'Кто-нибудь еще видит мои приватные фотографии?',
              textHtml: `<div>
<p>Ваши приватные фотографии могут видить только те пользователи, которым Вы отправили эти фотографии в письме и/или в чате.</p>
<p>Никто не может видеть Ваш приватный альбом, кроме Вас. Такой приватный альбом необходим для того, чтобы Вы могли загружать фотографии, недоступные для всех пользователей. Вы можете отправить эти фотографии с письмом мужчине, которого выбрали.</p>
</div>`,
            },
            // 2.5
            {
              questionName: 'Зачем я должна подмигивать мужчинам?',
              textHtml: `<div>
<p>Вы можете отправить мужчине только одно подмигивание в день. Каждый раз, когда Вы посылаете ему подмигивание, мужчина видит уведомление об этом. Вы также появляетесь в списке его подмигиваний. Таким образом, Вы привлекаете его внимание.</p>
</div>`,
            },
            // 2.6
            {
              questionName: 'Зачем мне список избранных и закладки?',
              textHtml: `<div>
<p>Вы можете добавить мужчину в свой список избранных, или сам мужчина может отправить запрос на добавление его в фавориты. Если Вы подтвердите его запрос, Вы сможете видеть списки избранных друг друга, однако это возможно только по взаимному согласию. Мы предлагаем Вам добавить в этот список мужчин, с которыми Вы часто общаетесь, или можете добавить тех пользователей мужского пола, которые интересуют Вас больше других. Если Вы отклоните запрос мужчины или удалите его из списка избранных, он потеряет право отправлять запросы на добавление в фавориты. Если Вы хотите анонимно добавить любого мужчину в свои закладки, воспользуйтесь списком закладок. Мы советуем Вам не забыть позже написать любимому мужчине или посетить его профиль.</p>
</div>`,
            },
            // 2.7
            {
              questionName: 'Могу ли я видеть гостей моего профиля?',
              textHtml: `<div>
<p>Да, конечно. Если Вы хотите увидеть гостей, Вы можете легко сделать это, используя соответствующий раздел меню «Гости». Этот раздел фиксирует всех посетителей Вашего профиля.</p>
</div>`,
            },
            // 2.8
            {
              questionName: 'Могу ли я отключить уведомления?',
              textHtml: `<div>
<p>Конечно, это возможно! В «Настройках» Вы можете отключить визуальные или звуковые уведомления. Для этого нажмите на свой аватар в правом верхнем углу и выберите «Настройки».</p>
<p>Визуализацию системных уведомлений невозможно отключить.</p>
</div>`,
            },
            // 2.9
            {
              questionName: 'Каковы особенности при использовании сервиса?',
              textHtml: `<div>
<p>Используя наш сервис, Вы можете:</p>
<ul class="ul-styled">
<li>искать мужчин;</li>
<li>демонстрировать признаки внимания к мужчинам;</li>
<li>сохранять списки фаворитов и закладки;</li>
<li>отслеживать посетителей Вашего профиля;</li>
<li>писать письма мужчинам;</li>
<li>отправлять виртуальные подарки понравившимся мужчинам;</li>
<li>отслеживать самые интересные новости на сайте;</li>
<li>общаться в текстовом чате;</li>
<li>общаться в видео-чате;</li>
<li>общаться в приватном чате.</li>
</ul>
</div>`,
            },
            // 2.10
            {
              questionName: 'Есть ли у мужчин "приватные" фотографии? Как я могу их просмотреть?',
              textHtml: `<div>
<p>Конечно, у каждого мужчины на сайте есть альбом с его личными фотографиями. В случае, если Вы хотите увидеть все его фотографии, Вы можете отправить ему запрос или письмо и спросить, может ли он предоставить фотографии.</p>
<p>Мы советуем Вам для начала познакомиться с мужчиной, написать ему, попытаться узнать его лучше и только после этого попросить приватные фото. </p>
</div>`,
            },
            // 2.11
            {
              questionName: 'Что если я напишу письмо мужчине, а он не ответит?',
              textHtml: `<div>
<p>Администрация нашего сервиса не заявляет и не гарантирует, что мужчина ответит на Ваше письмо. Сначала постарайтесь быть интересной этому мужчине, подмигните ему и пригласите его в чат.</p>
</div>`,
            },
            // 2.12
            {
              questionName: 'Могу ли я использовать чат, если у меня нет камеры или микрофона? ',
              textHtml: `<div>
<p>Если у Вас нету камеры, Вы имеете возможность использовать только текстовый чат.</p>
</div>`,
            },
            // 2.13
            {
              questionName: 'В чем разница между текстовыми, видео и приватными чатами?',
              textHtml: `<div>
<p>В текстовом чате Вы можете общаться с мужчинами, используя только текст и смайлы. Видео чат дает Вам возможность показать только себя и просмотреть видео с камеры мужчины. Приватный чат предоставляет Вам больше возможностей: Вы можете включить камеру и общаться друг с другом «лицом к лицу». В приватном чате тоже можно показывать себя и видеть мужчину. Окно приватного чата отличается от двух других чатов, что позволяет Вам наслаждаться общением с партнером, которого Вы выбрали, настолько комфортно, насколько это возможно.</p>
</div>`,
            },
            // 2.14
            {
              questionName: 'Могу ли я получать фото от клиента сервиса?',
              textHtml: `<div>
<p>Нет, сервис не предоставляет такой возможности. Все фото мужчин Вы можете просмотреть в их альбомах, зайдя на их личные страницы.</p>
</div>`,
            },
            // 2.15
            {
              questionName: 'Могу ли я отправлять мужчинам свои фото?',
              textHtml: `<div>
<p>Конечно! Вы можете отправлять свои фото из приватного альбома тем пользователям, с которыми у Вас есть активный чат и такие пользователи-мужчины должны быть в Вашем списке избранных. Каждое фото можно отправлять конкретному пользователю только 1 раз. </p>
</div>`,
            },
            // 2.16
            {
              questionName:
                'Как я могу пригласить мужчину в чат, не отвлекаясь от активных чатов в реальном времени?',
              textHtml: `<div>
<p>Вы легко можете пригласить мужчину в чат, из тех кто онлайн во время уже активного чата с другими собеседниками, воспользовавшись функцией авто-инвайт (авто-приглашение). В левом верхнем углу окна чата, нажмите настройки auto-invite, в открывшемся модельном окне Вы можете создать шаблон приглашений в чат и список исключений мужчин, которые не должны их получать. После того, как Вы сохраните настройки, то нажмите на кнопку рядом с настройками, чтобы активировать авто-приглашения. Вы можете отправлять инвайты не отвлекаясь от активных чатов с другими собеседниками. Обратите Ваше внимание, что авто-инвайты не будут доставлены пользователям, с которыми был чат менее 24 часов назад.</p>
</div>`,
            },
            // 2.17
            {
              questionName: 'Есть ли история сообщений в чате?',
              textHtml: `<div>
<p>Настройки чата позволяют записывать Вашу переписку во время текущей сессии окна браузера. В случае, если Вы закроете окно браузера или сам браузер, история сообщений будет удалена. В то же время каждый мужчина, с которым Вы общались, сохраняется в списке «Ваши контакты».</p>
</div>`,
            },
            // 2.18
            {
              questionName: 'Если я закрою окно чата, он остановится?',
              textHtml: `<div>
<p>После закрытия браузера все активные чаты с Вашими собеседниками прекратятся, а история переписки будет удалена. </p>
</div>`,
            },
            // 2.19
            {
              questionName: 'Видео из видео-чата - это запись, не так ли?',
              textHtml: `<div>
<p>Нет, видеоизображение передается в режиме реального времени с камеры мужчины.</p>
</div>`,
            },
            // 2.20
            {
              questionName: 'Могу ли я отправить подарок мужчине?',
              textHtml: `<div>
<p>Да, Вы можете отправить виртуальный подарок мужчине не более одного раза в месяц. Вы имеете право отправлять виртуальные подарки только тем мужчинам, которые добавлены в Ваши фавориты. Вы можете отправить виртуальный подарок любой тематики в количестве 1 штука, а также Вы можете отправить один дополнительный подарок ко дню рождения мужчины. Если в стране проживания мужчины отмечают национальные праздники, такие как Новый Год, Рождество, Пасха или Хеллоуин, то Вам разрешается отправить еще один дополнительный виртуальный подарок. Вместе с подарком Вы имеете полное право написать сопроводительное письмо с количеством символов не более 140. </p>
</div>`,
            },
            // 2.21
            {
              questionName:
                'Как виртуальный подарок, который я заказываю на сайте, попадает к мужчине? ',
              textHtml: `<div>
<p>Как только Вы заказали виртуальный подарок для мужчины, Ваш заказ будет обработан. После обработки Ваш виртуальный подарок попадает в раздел «Подарки» в его профайле.</p>
</div>`,
            },
            // 2.22
            {
              questionName:
                'Можно ли сделать виртуальный подарок, которого нет в списке на сервисе?',
              textHtml: `<div>
<p>К сожалению, это невозможно. Если Вы хотите подарить подарок, которого нет в списке, вы можете отправить запрос на него через администратора Вашего агентства, используя форму обратной связи или по электронной почте. Возможно, в будущем, мы добавим подарки в список, которые Вы предложили.</p>
</div>`,
            },
          ],
        },
        // 3
        {
          active: false,
          sectionName: 'Общение и отношения между участниками на сайте:',
          questions: [
            // 3.1
            {
              questionName: 'Есть ли на сайте участники в возрасте до 18 лет?',
              textHtml: `<div>
<p>В случае, если Вы заподозрили, что на сайте есть пользователи менее 18 лет, Вы можете описать эту проблему Вашему администратору в письменной форме, или воспользоваться электронной почтой и/или формой обратной связи. </p>
</div>`,
            },
            // 3.2
            {
              questionName: 'На каких языках мужчины будут общаться со мной на данном сервисе? ',
              textHtml: `<div>
<p>Наш сервис обязует всех пользователей общаться на английском языке, уровень которого можно увидеть в профайле каждого мужчины в отдельности.</p>
</div>`,
            },
            // 3.3
            {
              questionName: 'Есть ли на вашем сервисе услуги перевода на английский язык в чате?',
              textHtml: `<div>
<p>Да, наш сервис предоставляет возможность переводить Вашу переписку с собеседником в чате в онлайн режиме с помощью встроенного переводчика. Во время общения в чате Вы сможете переводить Ваши исходящие сообщения перед отправкой, а также входящие сообщения от пользователей-мужчин.</p>
</div>`,
            },
            // 3.4
            {
              questionName:
                'Как мне перевести входящее письмо от мужчины, а также правильно на него ответить, если мой уровень знания английского языка на низком уровне?',
              textHtml: `<div>
<p>Не переживайте, если Вы владеете иностранным языком на недостаточном для понимания уровне. Вы всегда можете обратиться к администрации Вашего агентства с просьбой о помощи в профессиональном переводе. Только так наш сайт может гарантировать достоверность и правильность перевода каждого предложения, что намного облегчит понимание и общение между Вами и Вашим собеседником. </p>
</div>`,
            },
            // 3.5
            {
              questionName: 'Могу ли я встретиться с мужчиной?',
              textHtml: `<div>
<p>Да, конечно! Вы можете встретится с мужчиной, которого Вы выбрали и общение с которым переросло в нечто большее. Для этого необходимо предоставить свою контактную информацию по его запросу и принять его приглашение о встрече. Администрация Вашего сервиса рекомендует организовать первую встречу исключительно с Вашим фаворитом и только на территории страны Вашего проживания. Вы должны помнить, что встречи возможны только по взаимному согласию сторон. Перед тем, как сделать такой серьезный шаг, мы предлагаем Вам обсудить с любимым человеком Ваше намерение встретиться с ним, а также остальные детали его приезда.</p>
</div>`,
            },
          ],
        },
        // 4
        {
          active: false,
          sectionName: 'Технические вопросы:',
          questions: [
            // 4.1
            {
              questionName: 'Каковы системные требования для сайта?',
              textHtml: `<div>
<p>Необходимая скорость соединения до 50 Мбит / с и пинг 100 мс.</p>
<p>Для использования сайта желательно использовать один из браузеров:</p>
<ul class="ul-styled">
<li>Internet Explorer 10 и выше</li>
<li>Опера 15.0 и выше</li>
<li>Google Chrome 19.0 и выше</li>
<li>Mozilla Firefox 13.0 и выше</li>
<li>Safari 6/0 и выше</li>
</ul>
</div>`,
            },
            // 4.2
            {
              questionName: 'Что делать, если я обнаружил ошибку на сайте?',
              textHtml: `<div>
<p>Вы должны сообщить об этой проблеме администратору Вашего агентства или в службу поддержки нашего сайта или по электронной почте. Как только мы получим от Вас письмо, мы проверим всю предоставленную информацию.</p>
</div>`,
            },
          ],
        },
      ],
    };
  },
  computed: {
    contentComp() {
      return { template: '<p><router-link :to="{name: \'terms\'}">link</router-link></p>' };
    },
    width() {
      return this.$store.getters.deviceWidth;
    },
    subTitle() {
      return this.sex.isWoman ? this.subTitleWoman : this.subTitleMan;
    },
    faqLists() {
      return this.sex.isWoman ? this.faqListsWoman : this.faqListsMan;
    },
    sex() {
      return this.$store.getters.sex;
    },
    restoreModal() {
      return this.$store.getters.restoreProfileModal;
    },
    supportEmail() {
      return this.$store.getters.siteSettings.support_email
    },
  },
  mounted() {
    document.title = 'F.A.Q';
  },
  methods: {
    scrollToY(scrollTargetY, speed, easing) {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const scrollTarget = scrollTargetY || 0;
      const speeds = speed || 500;
      const easings = easing || 'easeInOutSine';
      let currentTime = 0;
      const time = Math.max(0.1, Math.min(Math.abs(scrollY - scrollTarget) / speeds, 0.8));
      const easingEquations = {
        easeOutSine(pos) {
          return Math.sin(pos * (Math.PI / 2));
        },
        easeInOutSine(pos) {
          return -0.5 * (Math.cos(Math.PI * pos) - 1);
        },
        easeInOutQuint(pos) {
          if ((pos /= 0.5) < 1) {
            return 0.5 * Math.pow(pos, 5);
          }
          return 0.5 * (Math.pow(pos - 2, 5) + 2);
        },
      };

      function tick() {
        currentTime += 1 / 60;
        const p = currentTime / time;
        const t = easingEquations[easings](p);
        if (p < 1) {
          requestAnimationFrame(tick);
          window.scrollTo(0, scrollY + (scrollTargetY - scrollY) / t);
        } else {
          window.scrollTo(0, scrollTargetY);
        }
      }

      tick();
    },

    toggleAccordion(e, index) {
      const faqLists = this.sex.isWoman ? this.faqListsWoman : this.faqListsMan;
      const to = e.target.offsetHeight;
      const w = this.width > 992 ? 50 : 90;

      if (faqLists[index].active) {
        faqLists[index].active = false;
      } else {
        faqLists.forEach((x) => (x.active = false));
        faqLists[index].active = true;
      }
      if (faqLists[index].active) {
        if (to + w > document.documentElement.clientHeight) {
          setTimeout(() => {
            this.scrollToY(e.target.offsetHeight - w, 300);
          }, 300);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.expand-enter-active,
.expand-leave-active {
  transition-property: opacity, height;
}
.expand-enter,
.expand-leave-to {
  opacity: 0;
}

@import '../../../assets/scss/_variables';
@import '../../../assets/scss/vars';

.increment {
  ol {
    &.open {
      display: block;
    }
  }

  .rb-page--list__a {
    display: block;

    > p {
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.57;
      letter-spacing: 1.4px;
      margin-top: -5px;
      border-bottom: 1px solid #d9d9d9;
      padding: 10px 0;
    }

    > div {
      margin-left: 2.8rem;
    }

    &:before {
      content: '?';
      display: inline-flex;
      padding-right: 0;
      align-items: center;
      justify-content: center;
      color: $red;
      font-family: 'Maitree', serif;
      font-size: 25px;
      font-weight: 300;
      line-height: 1.04;
      letter-spacing: 1.25px;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      width: 36px;
      height: 36px;
      border: 1px solid;
      margin-right: 0.6rem;
    }
  }
}

.section-name-wrap {
  display: flex;
}

.rb-page {
  &--list {
    &__main {
      > li {
        &:before {
          display: none !important;
        }
      }
    }
  }
}

span.increment {
  margin-right: 0.6rem;
  &:before {
    color: $red;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
    content: counters(item, '.') '. ';
  }
}

.faq-section-name {
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 1px;
  display: block;
  position: relative;
  padding-right: 46px;
  margin-bottom: 0;
  width: 100%;
  &:after {
    content: '';
    display: inline-block;
    height: 1px;
    vertical-align: middle;
    width: 100%;
    margin-right: -100%;
    margin-left: 10px;
    background: #d9d9d9;
  }

  &:before {
    content: '\e90f';
    font-family: 'rbi-icons';
    position: absolute;
    right: 0;
    bottom: 3px;
    background: #fff;
    padding: 0 10px;
    transition: 0.3s;
    transform-origin: 50% 50%;
    transform: rotate(0);
    color: $black;
    font-size: 14px;
  }

  &.open {
    color: $red;

    &:before {
      transform: rotate(180deg);
    }
  }
}

.rb-page-faq {
  padding: 35px 0;
}

.faq {
  &-item {
    &__title {
      color: $black;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.57;
      letter-spacing: 1.4px;
      display: flex;
      align-items: center;

      &:before {
        content: '?';
        display: inline-block;
        color: $red;
        font-family: 'Maitree', serif;
        font-size: 25px;
        font-weight: 300;
        line-height: 36px;
        letter-spacing: 1.25px;
        width: 36px;
        min-width: 36px;
        height: 36px;
        box-shadow: inset 0 0 1px 1px rgba(226, 25, 25, 0.45);
        border: 1px solid $red;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
        text-align: center;
        margin-right: 1.25rem;
      }
    }

    &__answer {
      margin-left: 3.5rem;
      border-top: 1px solid #d9d9d9;
      position: relative;
      padding: 35px 0 20px;

      &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        width: 30px;
        height: 3px;
        display: block;
        background: $red;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .faq {
    &-item {
      &__title {
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        &:before {
          margin-bottom: 15px;
          align-self: center;
          margin-right: 0;
        }
      }
      &__answer {
        margin-left: 0;
        margin-top: 15px;
        padding: 25px 0 15px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .faq-section-name {
    font-size: 18px;
    line-height: 1.44;
    letter-spacing: 0.9px;
  }
}

/*  .faq {

      ol {
        counter-reset: list;

        li {
          position: relative;

          &:before {
            counter-increment: list;
            content: counters(list, ".") " ";
            position: absolute;
            top: 0;
            left: 0;
          }

        }
      }

      &--main {

        > li {
          text-align: center;

          &:before {
            opacity: 0;
            visibility: hidden;
            content: counters(list, ".") ". " !important;
            line-height: 1;
            top: 1px !important;
            font-size: 22px;
          }
        }

      }

      &--list {
        margin-top: 20px;
        text-align: left;

        &__item + &__item {
          margin-top: 20px;
        }

        &__item {
          padding-left: 70px;

          &:before {
            height: 50px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            color: $brand-primary;
            border: 2px solid $brand-primary;
            border-radius: 50%;
          }

          a {
            color: $brand-primary;
          }
        }

        &__sub {
          color: #a7a9ad;
          font-size: 11px;
          font-style: italic;
        }

        &__q {
          font-size: 15px;
          font-weight: bold;
        }

        &__q + &__sub {
          margin-top: 10px;
        }

        hr {
          margin: 10px 0;
        }

        &__a {
          line-height: 2;

          li {
            &:before {
              display: none;
            }
          }
        }
      }

      &--section + &--section {
        margin-top: 40px;
      }

      .index-navbar,
      .user-navbar {
        position: static;
      }

      .relative {
        position: relative;
        top: 0;
      }

      .white-box {
        padding: 60px;
      }

      &--header {
        margin-bottom: 30px;

        &__sub {
          font-size: 15px;
          margin-bottom: 30px;
          font-weight: bold;
        }

        &__section {
          display: inline;

          &:before {
            content: counters(list, ".") ". " !important;
            line-height: 1;
            top: 1px !important;
            font-size: 22px;
          }
        }
      }

    }

    @media only screen and (max-width: 992px) {
      .faq {
        &--header {
          &__sub {
            margin-bottom: 15px;
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {

      .faq {
        &--list {
          &__item {
            padding-top: 70px;
            padding-left: 0;

            &:before {
              left: 50% !important;
              transform: translateX(-50%);
            }
          }
        }
      }

    }*/

p,
.rb-page .ul-styled li {
  margin-bottom: 0.5em;
}
</style>
