<template>
  <component :is="contentComp" />
</template>

<script>
export default {
  name: 'DynamicContent',
  props: ['text'],
  computed: {
    contentComp() {
      return { template: `${this.text}` };
    },
  },
};
</script>

<style scoped>

</style>
