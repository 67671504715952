<template>
  <div class="mooeditable-wrap">
    <textarea ref="textarea" v-model="msg" :name="name" :placeholder="placeholder" />
  </div>
</template>

<script>
export default {
  name: 'ContentEditable',
  props: ['value', 'name', 'placeholder', 'clear'],
  data() {
    return {
      msg: '',
      showPlaceholder: true,
    };
  },
  watch: {
    /**
     * Показывать ли плейсхолдер
     * @param v {string}
     */
    msg(v) {
      if (!v || !v.length) this.showPlaceholder = true;
      this.saveMessage();
    },
    /**
     * Очистить текст сообщения
     * @param v {string}
     */
    clear() {
      // this.editable.iframe.contentDocument.body.innerHTML = '';
      this.showPlaceholder = true;
    },
  },
  mounted() {
    // this.initTextArea();
    if (this.value.length) {
      this.showPlaceholder = false;
      this.msg = this.value;
    }
  },
  beforeUpdate() {
    if (this.value.length) {
      this.showPlaceholder = false;
    }
  },
  methods: {
    /**
     * Передаёт события о вводе текста с очисткой от лишнего "мусора"
     */
    saveMessage() {
      this.msg.replace(/&nbsp;/gm, '').replace(/\s+/gm, ' ');
      this.$emit('clearText', this.msg); // for validation
      this.$emit('input', this.msg);
    },
  },
};
</script>

<style lang="scss">
.mooeditable {
  &-wrap {
    position: relative;
    height: 100%;
    textarea {
      width: 100%;
      overflow: hidden;
      background: none;
      border: none !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      //appearance: none;
      resize: none;
    }
  }
  &-placeholder {
    position: absolute;
    top: 15px;
    left: 10px;
    color: #000000;
    line-height: 1.2;
    pointer-events: none;
  }
  &-container {
    width: 100% !important;

    iframe {
      height: 100% !important;
      width: 100%;
    }
  }
}
</style>
